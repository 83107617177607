module.exports = [{
      plugin: require('/codebuild/output/src339902473/src/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/codebuild/output/src339902473/src/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src339902473/src/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
